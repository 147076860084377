<template>
  <div id="bannerBelow">
    <div
      @click="jumpUrl(item.id)"
      class="imgBox"
      v-for="(item, i) in data"
      :key="i"
    >
      <img :src="item.image" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "bannerBelow",
  data() {
    return {
      data: [
        { image: "https://static.scytyy.net/test/band1.png", id: 276 },
        { image: "https://static.scytyy.net/test/band2.png",id:207},
        { image: "https://static.scytyy.net/test/band3.png",id:253},
        { image: "https://static.scytyy.net/test/band4.png",id:159},
      ],
    };
  },
  methods: {
    jumpUrl(id) {
      const { href } = this.$router.resolve({
        path: "/index/allGoods",
        query: { brand_id: id },
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
#bannerBelow {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  .imgBox {
    width: 288px;
    height: 100px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
  }
}
</style>