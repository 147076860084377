<template>
  <div>
    <banner :bgBanner="pageData" />
    <section>
      <div class="home_container">
        <div class="content">
          <bannerBelow></bannerBelow>
          <div v-if="seckillData.goods" class="box">
            <seckill v-if="seckillData.goods" :seckills="seckillData" id="100" />
            <!-- <coupon v-if="couponData.length>0" :coupon="couponData" /> -->
          </div>
          <liveColumn v-if="liveData.goods" :data="liveData"></liveColumn>
          <div v-for="(items, index) in style_arr" :key="index" :id="items.list[0].id" class="box">
            <!-- <backdrop v-if="items.floor_tyle == 8" :data="items.list" :id="items.id" /> -->
            <!-- <styleOne v-if="items.floor_tyle == 7" :data="items.list" :id="items.id" /> -->
            <!-- 调整后 -->
            
            
            <!-- 上面广告下面5个商品 -->
            <backdrop v-if="items.floor_tyle == 6" :data="items.list" :id="items.id" />
            <!-- 左右两个模块：每个模块左边广告右边三个商品 -->
            <styleOne v-if="items.floor_tyle == 8" :data="items.list" :id="items.id" />
            <!-- 上面是图下面三个 -->
            <!-- <styleTwo v-if="items.floor_tyle == 6" :data="items.list" :id="items.id" /> -->
            <!-- 左边广告右边两排8个产品；没有广告就是两排一共十个产品 -->
            <styleFive v-if="items.floor_tyle == 7" :data="items.list" :id="items.id" />
            
            <!-- 品牌区域 -->
            <brandBottom v-if="items.floor_tyle == 9" :data="items.list" :id="items.id" />
            

            
            
          </div>
          
        </div>
      </div>
    </section>
    <floornav :list="sideArr" />
    <popup />
    <div v-if="qiandao_arr.isqd == 101" @click="homesign" class="qiandao">
      <img :src="qiandao_arr.img" alt="" />
    </div>
  </div>
</template>

<script>
import banner from "./components/banner";
import seckill from "./components/area/seckill.vue";
// import coupon from "./components/area/coupon.vue";
import backdrop from "./components/area/backdrop.vue";

import styleOne from "./components/area/styleOne.vue";
// import styleTwo from "./components/area/styleTwo.vue";
// import styleThree from "./components/area/styleThree.vue";
// import styleFour from "./components/area/styleFour.vue";
import styleFive from "./components/area/styleFive.vue";
// import styleSix from "./components/area/styleSix.vue";

import brandBottom from "./components/area/brandBottom.vue";
import liveColumn from "./components/area/liveColumn.vue";

import floornav from "@/components/floornav.vue";

import popup from "./components/popup/index.vue";
import bannerBelow from "./components/area/bannerBelow.vue";
export default {
  components: {
    banner,
    seckill,
    // coupon,
    backdrop,
    styleOne,
    // styleTwo,
    // styleThree,
    // styleFour,
    styleFive,
    // styleSix,
    brandBottom,
    floornav,
    popup,
    liveColumn,
    bannerBelow
  },
  data() {
    return {
      pageData: this.$store.state.homeData,
      list: [],
      sideArr: [],
      // seckill: this.$store.state.homeData.seckill,
      seckillData: {
        goods: [],
      },
      liveData:{ },
      // coupon: this.$store.state.homeData.banner3,
      couponData: [],
      style_arr: this.$store.state.homeData.floors,
      qiandao_arr: {
        isqd: "102",
      },
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    // this.getSign();
  },
  watch: {
    $route(to) {
      if (to.path == "/index/home") {
        // this.isHome = true;
      } else {
        // this.isHome = false;
      }
    },
  },
  methods: {
    getData() {
      this.$api("home.hoemData").then((res) => {
        this.$store.commit("SAVE_HOMEDATA", res.data);
        this.pageData = res.data;
        this.seckillData = res.data.seckill;
        this.liveData = res.data.live
        this.couponData = res.data.banner3;
        this.style_arr = res.data.floors;
        if (this.seckillData && this.couponData) {
          this.list.push({
            title: res.data.seckill.title,
            id: "100",
          });
        }
        this.sideArr = this.pageData.floors.map((v) => {
          let obj = {
            id: v.list[0].id,
            title: v.list[0].title,
          };
          return obj;
        });
        // console.log("侧边栏数据组装---", this.sideArr);
      });
    },
    getSign() {
      this.$api("account.signread").then((res) => {
        this.qiandao_arr = res.data;
      });
    },
    homesign() {
      this.$api("account.signsave").then(() => {
        this.$notify({
          title: "签到成功",
          // message: '这是一条成功的提示消息',
          type: "success",
          position: "bottom-right",
          duration: "1500",
        });
        this.getSign();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home_container{
  // background-image:url(https://static.scytyy.net/test/home_bj.jpg) ;
}
.content {
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
  
  .box {
    display: flex;
    justify-content: space-between;
    width: 1200px;
  }
}
.qiandao {
  position: fixed;
  bottom: 100px;
  right: 0px;
  width: 100px;
  height: 100px;
  background-color: red;
  z-index: 10000;
}
</style>
